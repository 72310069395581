import '../App.css';
import {Component} from "react";
import watcher1 from "../assets/img/monke_2.png";
import watcher2 from "../assets/img/thug_5.png";
import watcher3 from "../assets/img/monke_3.png";
import watcher4 from "../assets/img/thug_3.png";
import watcher5 from "../assets/img/monke_5.png";
import twitterImage from "../assets/img/back2back_twitter.jpeg";

import { Helmet } from "react-helmet";


class Home extends Component {
    componentDidMount() {
        document.body.style.paddingTop = "0px";
        document.body.style.backgroundColor = "#c9b2ff"
    }

    componentWillUnmount() {
        document.body.style.backgroundColor = "none"
    }

    render() {

        return <div className="main-wrapper">
                    <Helmet>
                        <title>Back2back - Coming Soon</title>
                        <meta name="description" content="The Back2Back project is coming" />
                        <meta name="twitter:card" content="summary_large_image" />
                        <meta name="twitter:site" content="@backDAO" />
                        <meta name="twitter:creator" content="@r0b0sapiens" />
                        <meta name="twitter:title" content="Back2Back - Coming Soon" />
                        <meta name="twitter:description" content="You won't believe what's coming" />
                        <meta name="twitter:image" content={twitterImage}/>
                    </Helmet>
                    <main>
                        <section className="home_page letter--1">
                            <div className="container">
                                <div className="home_page__inner" style={{textAlign:"center",
                                    height:"100vh",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"}}>
                                    <p className="coming_soon">
                                    COMING SOON
                                    </p>

                                    <img src={watcher1} alt="logo"/>
                                    <img src={watcher2} alt="logo"/>
                                    <img src={watcher3} alt="logo"/>

                                    <img src={watcher4} alt="logo"/>
                                    <img src={watcher5} alt="logo"/>
                                </div>
                            </div>
                        </section>
                    </main>
                </div>
    }
}

export default Home;

// import logo from './logo.svg';
// import './App.css';

import Main from "./components/Main";

function App() {
  return (
            <>
              <Main />
            </>
        );
}

export default App;

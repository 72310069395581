import logo from '../assets/img/logo.png';
import monke1 from '../assets/img/monkey-1.png'
import monke2 from '../assets/img/monkey-2.png'
import monke3 from '../assets/img/monkey-3.png'
import twitterImage from "../assets/img/letter_monkeDAO_twitter.jpeg";

import '../assets/styles/style.scss';
import {Component} from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord } from '@fortawesome/free-brands-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import {Helmet} from "react-helmet";

const discordUrl = "https://discord.gg/mfWThGum4b";

 class MonkeDAOLetter extends Component {
    render () {
        return <div className="main-wrapper">
            <Helmet>
                <title>Back2back - Letter to MonkeDAO</title>
                <meta name="description" content="Open letter to MonkeDAO" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@backDAO" />
                <meta name="twitter:creator" content="@r0b0sapiens" />
                <meta name="twitter:title" content="Back2Back - Open Letter to MonkeDAO" />
                <meta name="twitter:description" content="Dear Monkes" />
                <meta name="twitter:image" content={twitterImage} />
            </Helmet>
            <header className="header-letter">
                <div className="container" style={{position:"relative"}}>
                    <a href="/" className="logo">
                        <img src={logo} alt="logo"/>
                        <div className="logo__text">r0b0sapiens <br/> for @monkeDAO</div>
                    </a>
                    {/*<a style={{float:"right",*/}
                    {/*    marginRight:"10px",*/}
                    {/*    fontSize: "2.5rem",*/}
                    {/*    color: "black",*/}
                    {/*    position: "absolute",*/}
                    {/*    top: "10px",*/}
                    {/*    right: "1rem"*/}
                    {/*}} href="https://twitter.com/monkeDAO"  target="_blank" rel="noreferrer">*/}
                    {/*    <FontAwesomeIcon icon={faTwitter} />*/}
                    {/*</a>*/}

                    <a style={{float:"right",
                        marginRight:"10px",
                        fontSize: "2.5rem",
                        color: "black",
                        position: "absolute",
                        top: "10px",
                        right: "5rem"

                    }} href={discordUrl} target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faDiscord} />
                    </a>
                </div>
            </header>

            <main>

                <section className="letter letter--2">
                    <div className="container">
                        <div className="letter__inner">
                            <p> Dear Monkes, </p>
                            <p>Let me start off by saying I love your community and I think what you’ve achieved together as a group to this point is beyond amazing. I’d wish I was part of your community and I would if only I would have entered this ecosystem a bit earlier.<br />
                                But I didn’t and just like me, a lot of people got priced out quickly from owning a Monke, one of the true OG NFT projects on Solana. The floor on SMB quickly rose to almost 300 SOL a short while ago. That made me feel a bit of FOMO. It  also made me think about the people who sold monkes early on and got priced out, those who never owned one and those who desperately want to get exposure to one of the most solid projects on Solana. That upfront cost for owning NFTs, almost 50,000 USD is a lot of money for anyone. It’s a huge barrier of entry to one of the safest bets in the NFT world, putting it out of reach for most of the people.</p>

                            <p>This got me thinking, how can I fix this, how can I help people? This is when I first thought about what would become the Back2Back project. I want it to give people back the perks and satisfaction of owning a piece of Solana history, something that will weather crypto winter and come out on top at the other end.</p>

                            <p>What is the Back2Back project? It’s simple. It’s the first double mint project that wants to bring back access to the most loved NFTs to everyone. I want to mint 5000 Monke Backs NFTs that represent the backs of original SMBs, in a beautiful 1:1 artistic reproduction. It will be a fair mint, so anybody (monke or not) will be able to mint a skeleton, zombie or alien Monke Back. It’s going to put Monke Backs NFTs into people’s hands at a fair price and with the proceeds I will build <a href={discordUrl} target="_blank" rel="noreferrer" className="backDAO">BackDAO</a>, the real end game of Back2Back.</p>

                            <p>What is <a href={discordUrl} target="_blank" rel="noreferrer" className="backDAO">BackDAO</a>? It’s an organization built to bring value to its members, but for the first time in NFT history, it aims to bring value to the two projects and communities it homages, MonkeDAO and thugDAO. In short, <a href={discordUrl} target="_blank" rel="noreferrer" className="backDAO">BackDAO</a> will use part of the proceeds from minting to proportionally market buy floor Monkes and Thugz and put them in the DAO treasury. </p>

                            <p>This will cause upward pressure on the floor price of both projects. Then comes part two and this is the game changer. This is where the magic happens and we all benefit from <a href={discordUrl} target="_blank" rel="noreferrer" className="backDAO">BackDAO</a>.<br />
                                The DAO will transparently fractionalise these NFTs on chain, by using an NFT fractionalisation protocol. Many are being developed right now and they are going to launch soon. The resulting fractions will be available only to <a href={discordUrl} target="_blank" rel="noreferrer" className="backDAO">BackDAO</a> members through NFT gated vaults so MonkeBacks and ThugBacks holders can acquire parts of real SMBs and Thugbirdz at a fair price and in any quantity. The proceeds will be used to acquire more floor NFTs from these two collections, causing even more upward pressure on the floor price. The <a href={discordUrl} target="_blank" rel="noreferrer" className="backDAO">BackDAO</a> will also set up monthly NFT fraction airdrops to members and take steps to set up permissionless liquidity pools on Serum DEX so members can sell or redistribute tokens.</p>

                            <p>This is what I mean by my Back2Back plan to get Monkes and Thugz back into everybody’s hands. People being able to acquire in a DAO, in a trustless manner, fractions of a solid OG NFT project. It’s my dream for the future of NFTs. It’s also about people forming a solid community around owning parts of two of the strongest NFT projects in the world. More people owning parts of SMBs means more people in touch and directly interested in the plans and perspectives of MonkeDAO. They will not be in MonkeDAO but they will strongly support it as they are directly interested in their investment to perform well. </p>

                            <p>Now you know why it’s called <a href={discordUrl} target="_blank" rel="noreferrer" className="backDAO">BackDAO</a>, Monke Back collection and the Back2Back project. It’s all about giving value back to the DAOs, back to the NFT communities and back to the simple NFT collectors who want to own a piece of a solid project.</p>

                            <p>I openly shared the Back2Back plan in the hope that MonkeDAO will strongly support this project. It will bring value in the long term directly to MonkeDAO members by increasing demand for SMB and increasing the number of people invested in the project, directly interested in its success.</p>

                            <p> I leave you with six words that sum this letter up: <br/>LET ME GET MY MONKE BACK!</p>

                            <p> With love for my monkes, humbly yours, <br/>
                                <a href="https://twitter.com/r0b0sapiens" target="_blank" rel="noreferrer" className="backDAO">r0b0sapiens</a></p>

                            <img src={monke1} alt="logo"/>
                            <img src={monke2} alt="logo"/>
                            <img src={monke3} alt="logo"/>
                        </div>
                    </div>
                </section>

            </main>


        </div>
    }
}

export default MonkeDAOLetter;

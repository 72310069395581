import '../App.css';
import { Component } from "react";


import thug2 from "../assets/img/thug-2.png";
import thug3 from "../assets/img/thug-3.png";
import duck1 from "../assets/img/duck-1.png";
import duck2 from "../assets/img/duck-2.png";
import duck3 from "../assets/img/duck-3.png";
import duck5 from "../assets/img/duck-5.png";
import jemmmy1 from "../assets/img/jemmmy-1.png";
import jemmmy2 from "../assets/img/jemmmy-2.png";
import jemmmy3 from "../assets/img/jemmmy-3.png";
import jemmmy4 from "../assets/img/jemmmy-4.png";

import sprite from '../assets/img/sprite.svg';

import { Helmet } from "react-helmet";


class Backstory extends Component {

    componentDidMount() {
        document.body.style.backgroundColor = "#ffffff"
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        document.body.style.backgroundColor = "none"
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        const header = document.querySelector('.header');
        if (
          document.body.scrollTop > 50 ||
          document.documentElement.scrollTop > 50
        ) {
          header.classList.add('active');
        } else {
          header.classList.remove('active');
        }
    }

    render() {

        return <>
                <div class="main-wrapper">
                    <header class="header">
                        <div class="container">
                            <div class="header__inner">
                                <nav>
                                    <ul>
                                        <li>
                                            <a href="./backstory.html">BACKSTORY</a>
                                        </li>
                                        <li>
                                            <a href="./backdao.html">BACKDAO</a>
                                        </li>
                                        <li>
                                            <a href="./roadmap.html">ROADMAP</a>
                                        </li>
                                        <li>
                                            <a href="./faq.html">FAQ</a>
                                        </li>
                                    </ul>
                                </nav>
                                <button class="burger menu-toggle">
                                    <svg>
                                    <use href={sprite + "#menu"}></use>
                                    </svg>
                                </button>
                                <button class="btn btn--transparent">CONNECT WALLET</button>
                            </div>

                            <div class="mmenu">
                                <button class="menu-toggle">
                                    <svg>
                                    <use href={sprite + "#close"}></use>
                                    </svg>
                                </button>
                                <nav>
                                    <ul>
                                        <li>
                                            <a href="./backstory.html">BACKSTORY</a>
                                        </li>
                                        <li>
                                            <a href="./backdao.html">BACKDAO</a>
                                        </li>
                                        <li>
                                            <a href="./roadmap.html">ROADMAP</a>
                                        </li>
                                        <li>
                                            <a href="./faq.html">FAQ</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </header>

                    <main>
                        <section class="hero">
                            <div class="container">
                                <div class="hero__inner">

                                    <div class="hero__logo">
                                        BACK <div>2</div> BACK
                                    </div>

                                    <h1>FIRST DOUBLE MINT WITH 2 COLLECTIONS,<br /> MINT PRICE JUST LIKE THE ORIGINAL COLLECTIONS, JUST 2 SOL.</h1>

                                    <div class="hero__btns">
                                        <button class="btn">MINT THUG BACK</button>
                                        <button class="btn">MINT MONKE BACK</button>
                                    </div>

                                    <div class="hero__imgs">
                                        <img src={duck5} alt="" />
                                        <img src={thug2}  alt="" />
                                        <img src={thug3}  alt="" />
                                        <img src={duck3}  alt="" />
                                        <img src={jemmmy1}  alt="" />
                                        <img src={jemmmy2}  alt="" />
                                        <img src={jemmmy3} alt="" />
                                        <img src={jemmmy4} alt="" />
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section class="backstory">
                            <div class="container">
                                <h2>backstory</h2>
                                <div class="backstory__imgs">
                                    <img src={duck5} alt="" />
                                    <img src={jemmmy3} alt="" />
                                </div>
                                <div class="backstory__content">
                                    <div class="backstory__col">
                                        <p>This project started when the floor for monkes reached 300 SOL and the one for</p>
                                        <p>thugz reached 150 SOL. These prices mean one thing. Owning a piece of the best collections on Solana is out
                                        of reach for those of us who came a bit later. And owning those is what we all want, exposure to the best
                                        performing NFTs. The safest way to invest in the future is always going with the blue chip projects which
                                        will weather any storm.</p>
                                    </div>
                                    <div class="backstory__col">
                                        <p>That’s when I knew I had to do something. Something for all of us who didn’t get the</p>
                                        <p>chance to own monkes or thugz but want to be part of something great. Something the normal NFT collector
                                        can reach. Something strong, built for the future that would also bring thugz and monkes closer together and
                                        reward these two communities while creating another one. <br /><br /> I knew I had to build Back2Back.</p>
                                    </div>
                                </div>
                                <div class="backstory__imgs backstory__imgs--bottom">
                                    <img src={jemmmy4} alt="" />
                                    <img src={duck2} alt="" />
                                    <img src={duck3} alt="" />
                                </div>
                            </div>
                        </section>

                        <section class="about">
                            <div class="container">
                                <div class="about__inner">
                                    <h2>WHAT’S BACK2BACK?</h2>
                                    <p>I always like to innovate so Back2Back is the first project that will have a double mint. Two mint buttons for
                                        minting 2 separate collections at the same time. I’ve designed, as an homage to these wonderful communities, two
                                        collections that feature a 1:1 reproduction I imagined for the backs of the most recognisable PFPs in our
                                        ecosystem, thugbirdz and SMBs. They have all the features of the original come complete with metadata and the
                                        same rarity scheme.</p>
                                    <h2>WHY DO THIS?</h2>
                                    <p>I want everybody to get the chance to mint a skeleton or zombie monke or a cool spliff smoking gold chain tron
                                        glass thug. Minting is gonna be super exciting! <br /><br />I want everybody to be able to own a part of Solana
                                        history for a fair price. <br /><br />I want to bring the joy of owning thugz and monkes backs, back to
                                        everybody.<br /><br /> I want original SMB and Thugz to search for their NFT backs and make the character complete.
                                        <br /><br />But most of all I want to fund BackDAO, an organisation owned and operated by holders of tokens from
                                        these two collections.</p>
                                    <div class="about__img">
                                        <img src={thug2} alt="" />
                                    </div>
                                    <div class="about__img">
                                        <img src={jemmmy1} alt="" />
                                    </div>
                                    <div class="about__imgs">
                                        <img src={duck5} alt="" />
                                        <img src={thug2} alt="" />
                                        <img src={duck3} alt="" />
                                        <img src={thug3} alt="" />
                                        <img src={duck1} alt="" />
                                    </div>
                                </div>
                            </div>
                        </section>

                    <section class="backdao">
                        <div class="container">
                            <div class="backdao__inner">
                            <h2>BACKDAO</h2>
                            <p>BackDAO is an organisation that serves two purposes:<br /><br /> bring value to the holders of tokens from the
                                @MonkeBacks and @ThugBacks collections through exclusive access to NFT defi instruments.<br /><br /> bring value to
                                the communities around the two original projects @MonkeDAO and @thugDAO.
                            </p>
                            </div>
                            <div class="backdao__imgs">
                            <img src={jemmmy2} alt="" />
                            <img src={jemmmy2} alt="" />
                            </div>
                        </div>
                    </section>

                    <section class="how">
                        <div class="container">
                            <div class="how__inner">
                                <h2>HOW DOES THIS WORK?</h2>
                                <ol>
                                    <li><span>1</span>Get funds from the mint and set up the BackDAO treasury.</li>
                                    <li><span>2</span>Market buy floor SMBs and thugbirdz, proportionally to the BackDAO treasury contributions.
                                    This first step
                                    will raise the floor of both projects, bringing value to both DAOs and consolidating their role in the market.
                                    <br /><br />
                                    These assets go into the BackDAO treasury.</li>
                                    <li><span>3</span>
                                    We transparently fractionalise these NFTs on chain, by using an upcoming open source fractionalisation
                                    protocol. We will build the necessary infrastructure over the protocol
                                    </li>
                                    <li><span>4</span>
                                    DAO members get access at token mint to buy and own parts of original SMBs and Thugz that they can freely
                                    trade liquid tokens on Serum DEX. The price on the DEX will be higher as only a theoretical limit of 8333
                                    wallets get access at mint prices.</li>
                                    <li><span>5</span> DAO members will buy these fractions from the BackDAO treasury. These funds will be used to
                                    market buy more
                                    floor thugz and SMBs.</li>
                                    <li><span>6</span>This in term causes more supply pressure and increases the floor of the original projects.
                                    </li>
                                    <li><span>7</span> Everybody wins. thuDAO and MonkeDAO win because this will bring incredible upward pressure on
                                    the floor,
                                    BackDAO wins because members get access to fractions of NFTs that appreciate in value. <br /><br />
                                    In time funds from the DAO</li>
                                </ol>

                                <div class="how__imgs">
                                    <img src={duck3} alt="" />
                                    <img src={duck2} alt="" />
                                    <img src={duck1} alt="" />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="roadmap">
                        <div class="container">
                            <div class="roadmap__inner">
                                <h2>ROADMAP</h2>
                                <ol>
                                    <li><span>01/10</span><em>IDEA HITS ME</em>All started when SMBs hit 300 SOL and Thugbirdz hit 150 SOL</li>
                                    <li><span>02/10</span><em>DECIDED TO HELP</em>Decided to help people get back a piece of those blue chip NFTS
                                    </li>
                                    <li><span>03/10</span><em>STARTED WORKING ON B2B</em>Started working on Back2Back: first dual mint of @MonkeBacks
                                    and @ThugBacks</li>
                                    <li>
                                    <div class="roadmap__imgs">
                                        <img src={jemmmy1} alt="" />
                                        <img src={jemmmy3} alt="" />
                                    </div>
                                    </li>
                                    <li><span>04/10</span><em>STARTED WORKING ON B2B</em>Started working on Back2Back: first dual mint of @MonkeBacks
                                    and @ThugBacks</li>
                                    <li><span>05/10</span><em>OPEN LETTERS WENT OUT</em>Open letter of intent to the @MonkeDAO and @ThugDAO for
                                    public support</li>
                                    <li><span>06/10</span><em>FAIR MINT, FAIR PRICE</em>Mint day, fair mint, fair distribution of both collections,
                                    people getting in at a fair price</li>
                                    <li><span>07/10</span><em>SELL OUT THE ENTIRE COLLECTIONS</em>Sell out the entire collections, launch secondary
                                    marketplace sales. 2 separate markets, 2 floors, etc</li>
                                    <li><span>08/10</span><em>FAIR MINT, FAIR PRICE</em>Mint day, fair mint, fair distribution of both collections,
                                    people getting in at a fair price</li>
                                    <li><span>09/10</span><em>SELL OUT THE ENTIRE COLLECTIONS</em>Sell out the entire collections, launch secondary
                                    marketplace sales. 2 separate markets, 2 floors, etc</li>
                                    <li>
                                    <div class="roadmap__imgs">
                                        <img src={thug2} alt="" />
                                        <img src={jemmmy3} alt="" />
                                    </div>
                                    </li>
                                    <li><span>10/10</span><em>SELL OUT THE ENTIRE COLLECTIONS</em>Sell out the entire collections, launch secondary
                                    marketplace sales. 2 separate markets, 2 floors, etc</li>
                                </ol>
                            </div>
                        </div>
                    </section>
                </main>
            </div> 
        </>
    }
}

export default Backstory;

import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from './Home';
import ThugDAOLetter from "../pages/ThugDAOLetter";
import MonkeDAOLetter from "../pages/MonkeDAOLetter";
import Backstory from './Backstory';

const Main = () => {
    return (
        <Switch> {/* The Switch decides which component to show based on the current URL.*/}
            <Route exact path='/'>
                <Home />
            </Route>
            <Route exact path='/letter/thugDAO'>
                <ThugDAOLetter />
            </Route>
            <Route exact path='/letter/monkeDAO'>
                <MonkeDAOLetter />
            </Route>
            <Route exact path='/backstory'>
                <Backstory />
            </Route>
        </Switch>
    );
}

export default Main;
